import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  doesTagsContainNoIndex,
  getHTMLLang,
  getHrefLangTagProps,
  localizedPath,
  stringReplace
} from "../../utilities/utilities"
import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import { renderTags } from "../../utilities/renderTags"
import { Container } from "react-bootstrap"
import { getBlogCategoryPath } from "../../utilities/blogUtilities"
import t from "../../translations"
import SEO from "../../components/seo"
import Pager from "../../components/Pager/Pager"
import ArticleGrid from "../../components/ArticleGrid/ArticleGrid"
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb"

import styles from "./Category.module.scss"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import getValueOrDefault from "../../analytics/getValueOrDefault"

export const query = graphql`
  query (
    $pageId: String!
    $categoryId: String!
    $articleStartIndex: Int!
    $itemsPerPage: Int!
    $locale: String!
  ) {
    placeholder: file(
      relativePath: { eq: "placeholder-missingblogimage.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 260, aspectRatio: 1.3, layout: CONSTRAINED)
      }
    }
    contentfulPage(id: { eq: $pageId }) {
      ...pageFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
    contentfulBlogPostCategory(id: { eq: $categoryId }) {
      id
      slug
      name
      analyticalName
      subtitle
      metaTitle
      metaDescription
      segmentUpdatedAt: updatedAt(formatString: "yyyyMMDD")
      topContent {
        childMarkdownRemark {
          html
        }
      }
      footerContent {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [datePublished], order: [DESC] }
      skip: $articleStartIndex
      limit: $itemsPerPage
      filter: {
        node_locale: { eq: $locale }
        datePublished: { ne: null }
        category: { id: { eq: $categoryId } }
      }
    ) {
      nodes {
        id
        slug
        dateCreated(formatString: "dd MMM yyyy", locale: $locale)
        datePublished(formatString: "dd MMM yyyy", locale: $locale)
        title
        category {
          id
          slug
          name
        }
        thumbnail: mainImage {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            width: 512
            aspectRatio: 1.3
            resizingBehavior: FILL
            quality: 100
          )
        }
      }
    }
  }
`

const Category = props => {
  useScrollTracking()

  const { localeLinks, locale, defaultLocale, totalPages, pageIndex } =
    props.pageContext
  const articles = props.data.allContentfulBlogPost.nodes
  const page = props.data.contentfulPage
  const category = props.data.contentfulBlogPostCategory
  const currentPageNumber = pageIndex + 1

  usePageTracking({
    article_category: getValueOrDefault(category.analyticalName, category.slug)
  })

  const breadcrumb = [
    {
      id: localizedPath("", null, locale, defaultLocale),
      title: t.translate(t.KEYS.HOME, locale),
      url: localizedPath("", null, locale, defaultLocale)
    },
    {
      id: localizedPath("blog", null, locale, defaultLocale),
      title: "Blog",
      url: localizedPath("blog", null, locale, defaultLocale)
    },
    {
      id: props.pageContext.pageUrl,
      title: category.name,
      url: null
    }
  ]

  const stringReplacements = [
    {
      current_page: currentPageNumber,
      total_pages: totalPages
    },
    {
      current_category: category.name,
      page: currentPageNumber,
      total_pages: totalPages
    }
  ]

  const title = stringReplace(
    _.get(category, "metaTitle", ""),
    stringReplacements[0]
  )

  const metaDescription = stringReplace(
    _.get(category, "metaDescription", ""),
    stringReplacements[1]
  )

  const renderHead = () => {
    return (
      <div className="text-center">
        <h1 className={styles.categoryTitle}>
          {category.name} - {currentPageNumber}
        </h1>
        <p className={styles.categorySubtitle}>{category.subtitle}</p>
      </div>
    )
  }

  const renderSection1 = () => {
    if (!category.topContent) {
      return null
    }

    return (
      <div className={styles.topSection}>
        <div
          dangerouslySetInnerHTML={{
            __html: category.topContent.childMarkdownRemark.html
          }}
        />
      </div>
    )
  }

  const renderSection2 = () => {
    if (!category.footerContent) {
      return null
    }

    return (
      <Container>
        <div
          dangerouslySetInnerHTML={{
            __html: category.footerContent.childMarkdownRemark.html
          }}
        />
      </Container>
    )
  }

  const pageStartScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyStartScripts
  )
  const pageEndScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyEndScripts
  )
  const hasFixedMenu = _.defaultTo(_.get(page, "fixedMenu"), true)

  const htmlLang = getHTMLLang(locale)

  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`

  const linkGenerator = pageNumber => {
    return getBlogCategoryPath(category, pageNumber, locale, defaultLocale)
  }

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: _.get(page, "pagePerimeter", ""),
      pagePublication: category.segmentUpdatedAt,
      pageFeatureSector: _.get(page, "pageFeatureSector", "")
    },
    page.slug,
    props.path
  )
  const doesPageContainsNoIndexTag =
    doesTagsContainNoIndex(page.extraTags) ||
    (props.data.contentfulPage.template &&
      props.data.contentfulPage.template.headTags &&
      doesTagsContainNoIndex(props.data.contentfulPage.template.headTags))
  function renderHrefLangTags() {
    return getHrefLangTagProps(
      localeLinks,
      props.data.site.siteMetadata.siteUrl
    ).map(tagProps => {
      return <link {...tagProps} key={tagProps.key} />
    })
  }

  return (
    <Layout locale={locale} defaultLocale={props.pageContext.defaultLocale}>
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />
      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {renderTags(
          locale,
          props.data.site.siteMetadata.defaultLocale,
          page.extraTags
        )}
        {props.data.contentfulPage.template &&
          props.data.contentfulPage.template.headTags &&
          renderTags(
            locale,
            props.pageContext.defaultLocale,
            props.data.contentfulPage.template.headTags
          )}
        {currentPageNumber > 1 && (
          <link
            rel="prev"
            href={getBlogCategoryPath(
              category,
              currentPageNumber - 1,
              locale,
              defaultLocale
            )}
          />
        )}
        {currentPageNumber < totalPages && (
          <link
            rel="next"
            href={getBlogCategoryPath(
              category,
              currentPageNumber + 1,
              locale,
              defaultLocale
            )}
          />
        )}
        {renderHrefLangTags()}
      </Helmet>
      <Template
        {...page.template}
        isMenuFixed={hasFixedMenu}
        localeLinks={localeLinks}
        locale={props.pageContext.locale}
        defaultLocale={props.pageContext.defaultLocale}
      >
        {pageStartScriptTags}
        <div className={styles.category}>
          <Breadcrumb links={breadcrumb} />
          <ArticleGrid
            header={
              <>
                {renderHead()}
                {renderSection1()}
              </>
            }
            title={t.translate(t.KEYS.LATEST_ARTICLES, locale)}
            articles={articles}
            footer={
              <>
                {renderSection2()}
                {articles.length > 0 && (
                  <Pager
                    totalPages={totalPages}
                    currentPage={currentPageNumber}
                    linkGenerator={linkGenerator}
                  />
                )}
              </>
            }
            locale={locale}
            defaultLocale={defaultLocale}
          />
        </div>
        {pageEndScriptTags}
      </Template>
    </Layout>
  )
}

Category.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default Category
